import { useMemo, useReducer, useEffect, useCallback } from 'react'
import axios from 'axios';
import { ActionMapType, PersonalStateType, PersonalType } from './type';
import { PersonalContext } from './personal-context';

enum Types {
  INITIAL = 'INITIAL',
}

type Payload = {
  [Types.INITIAL]: {
    personalValues: PersonalType[];
  };
};

const initialState: PersonalStateType = {
  personalValues: [],
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const reducer = (state: PersonalStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        personalValues: action.payload.personalValues,
      };
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
};

export function PersonalProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initializePersonal = useCallback(async () => {
    if (process.env.REACT_APP_BACKEND_URL) {
      axios.get(process.env.REACT_APP_BACKEND_URL+'/api/get_all_personal_return_statuses')
        .then(response => {
          dispatch({
            type: Types.INITIAL,
            payload: {
              personalValues: response.data,
            }
          })
        })
        .catch(error => {
          console.error('There was an error!', error);
          dispatch({
            type: Types.INITIAL,
            payload: {
              personalValues: [],
            }
          })
        });
    }
  }, [])

  useEffect(() => {
    initializePersonal();
  }, [initializePersonal])

  const memoizedValue = useMemo(
    () => ({
      personalValues: state.personalValues,
      initializePersonal,
    }),
    [initializePersonal, state.personalValues]
  );

  return <PersonalContext.Provider value={memoizedValue}>{children}</PersonalContext.Provider>;
}