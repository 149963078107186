import { useContext } from "react";

import { FirmUserContext } from "../context";

export const useFirmUserContext = () => {
    const context = useContext(FirmUserContext);

    if (!context) throw new Error('useFirmUserContext context must be use inside FirmUserProvdier');

    return context;
}