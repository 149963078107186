import { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, useMediaQuery, useTheme } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import NewClientModal from "../NewClientModal";
import { useDataContext } from "../../context/data/hook";
import { Link } from "react-router-dom";

const DataGridToolbar = () => {
	const { taxYear, checkComplete, updateToComplete, initialize, setTaxYear, setCheckComplete } = useDataContext();
	const theme = useTheme()
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [openModal, setOpenModal] = useState(false);
	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);

	const currentYear = new Date().getFullYear();

	return (
		<Box
			sx={{
				p: 0.5,
				pb: 0,
				display: "flex",
				flexDirection: isMediumScreen ? 'column' : 'row',
				justifyContent: 'space-between'
			}}
		>
			<Box>
				<GridToolbarQuickFilter sx={{ width: isMediumScreen ? '100%' : '300px' }} />
				{isMediumScreen ?
					<Box>
						<Box display={"flex"} justifyContent={ isMediumScreen ? "" : "space-between"} gap={'20px'}>
							<Button sx={{ paddingX: '40px', paddingY: '5px', border: '1px solid #491D70', color: '#491D70' }}>Settings</Button>
							<Button sx={{
								paddingX: '40px', paddingY: '5px', border: '1px solid #491D70', color: '#FFF', backgroundColor: '#491D70', '&:hover': {
									backgroundColor: '#491d80',
								}
							}} onClick={handleOpen}>New Client</Button>
						</Box>
						<Box display={"flex"} justifyContent={isMediumScreen ? "flex-start" : 'flex-end'}>
							<FormControlLabel control={<Checkbox checked={checkComplete} onChange={(event) => {
								setCheckComplete(event.target.checked)
							}} />} label="Show Completed" labelPlacement="end" />
						</Box>
					</Box> : <></>
				}
				<Box display={"flex"} gap={'0px'} sx={{ fontSize: '13px' }}>
					{
						Array.from({ length: 3 }, (_, i) => currentYear - 1 - i).map((year, index) => (
							<Button key={index}
								onClick={() => setTaxYear(year)}
								sx={{
									border: '1px solid grey', backgroundColor: taxYear === year ? 'transparent' : '#F0F0F0',
									borderBottom: taxYear === year ? '1px solid white' : 'none',
									textTransform: 'none'
								}}>{`${year} Tax Returns`}</Button>
						))
					}
				</Box>
			</Box>
			{!isMediumScreen ?
				<Box>
					<Box display={"flex"} justifyContent={ isMediumScreen ? "" : "space-between"} gap={'20px'}>
						<Link to='/settings'><Button sx={{ paddingX: '40px', paddingY: '5px', border: '1px solid #491D70', color: '#491D70', textTransform: 'none' }}>Settings</Button></Link>
						<Button sx={{
							paddingX: '40px', paddingY: '5px', border: '1px solid #491D70', color: '#FFF', backgroundColor: '#491D70', '&:hover': {
								backgroundColor: '#491d80',
							}, textTransform: 'none'
						}} onClick={handleOpen}>New Client</Button>
					</Box>
					<Box display={"flex"} justifyContent={isMediumScreen ? "flex-start" : 'flex-end'}>
						<FormControlLabel control={<Checkbox checked={checkComplete} onChange={(event) => {
							setCheckComplete(event.target.checked)
							console.log(event.target.checked);
							event.target.checked ? updateToComplete() : initialize();
						}} />} label="Show Completed" labelPlacement="end" />
					</Box>
				</Box> : <></>
			}
			<NewClientModal open={openModal} handleClose={handleClose} />
		</Box>
	);
}

export default DataGridToolbar