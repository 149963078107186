import { useContext } from "react";

import { BookkeeperContext } from "../context";

export const useBookkeeperContext = () => {
    const context = useContext(BookkeeperContext);

    if (!context) throw new Error('useBookkeeperContext context must be use inside BookkeeperProvdier');

    return context;
}