import { HashRouter } from "react-router-dom";
import "./App.css";
import { DataProvider } from "./context/data/context";
import { BusinessProvider } from "./context/businessReturnStatus/context";
import { PersonalProvider } from "./context/personalReturnStatus/context";
import { SupportProvider } from "./context/supportOption/context";
import { BookkeeperProvider } from "./context/bookkeeper/context";
import { FirmUserProvider } from "./context/firmUsers/context";
import { AuthProvider } from "./context/auth/context";
import AllRoutes from "./routes/AllRoutes";

function App() {
  return (
    <AuthProvider>
      <FirmUserProvider>
        <BookkeeperProvider>
          <SupportProvider>
            <BusinessProvider>
              <PersonalProvider>
                <DataProvider>
                  <>
                    <HashRouter>
                        <AllRoutes />
                    </HashRouter>
                  </>
                </DataProvider>
              </PersonalProvider>
            </BusinessProvider>
          </SupportProvider>
        </BookkeeperProvider>
      </FirmUserProvider>
    </AuthProvider>
  );
}

export default App;
