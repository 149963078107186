import { useMemo, useReducer, useEffect, useCallback } from 'react'
import axios from 'axios';
import { ActionMapType, SupportStateType, SupportType } from './type';
import { SupportContext } from './support-context';

enum Types {
  INITIAL = 'INITIAL',
}

type Payload = {
  [Types.INITIAL]: {
    supportValues: SupportType[];
  };
};

const initialState: SupportStateType = {
  supportValues: [],
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const reducer = (state: SupportStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        supportValues: action.payload.supportValues,
      };
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
};

export function SupportProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initializeSupport = useCallback(async () => {
    if (process.env.REACT_APP_BACKEND_URL) {
      axios.get(process.env.REACT_APP_BACKEND_URL+'/api/get_all_support_options')
        .then(response => {
          dispatch({
            type: Types.INITIAL,
            payload: {
              supportValues: response.data,
            }
          })
        })
        .catch(error => {
          console.error('There was an error!', error);
          dispatch({
            type: Types.INITIAL,
            payload: {
              supportValues: [],
            }
          })
        });
    }
  }, [])

  useEffect(() => {
    initializeSupport();
  }, [initializeSupport])

  const memoizedValue = useMemo(
    () => ({
      supportValues: state.supportValues,
      initializeSupport,
    }),
    [initializeSupport, state.supportValues]
  );

  return <SupportContext.Provider value={memoizedValue}>{children}</SupportContext.Provider>;
}