import { useMemo, useReducer, useEffect, useCallback } from 'react'
import axios from 'axios';
import { ActionMapType, BusinessStateType, BusinessType } from './type';
import { BusinessContext } from './business-context';

enum Types {
  INITIAL = 'INITIAL',
}

type Payload = {
  [Types.INITIAL]: {
    businessValues: BusinessType[];
  };
};

const initialState: BusinessStateType = {
  businessValues: [],
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const reducer = (state: BusinessStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        businessValues: action.payload.businessValues,
      };
    default:
      return state;
  }
}

type Props = {
  children: React.ReactNode;
};

export function BusinessProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initializeBusiness = useCallback(async () => {
    if (process.env.REACT_APP_BACKEND_URL) {
      axios.get(process.env.REACT_APP_BACKEND_URL+'/api/get_all_business_return_statuses')
        .then(response => {
          dispatch({
            type: Types.INITIAL,
            payload: {
              businessValues: response.data,
            }
          })
        })
        .catch(error => {
          console.error('There was an error!', error);
          dispatch({
            type: Types.INITIAL,
            payload: {
              businessValues: [],
            }
          })
        });
    }
  }, [])

  useEffect(() => {
    initializeBusiness();
  }, [initializeBusiness])

  const memoizedValue = useMemo(
    () => ({
      businessValues: state.businessValues,
      initializeBusiness,
    }),
    [initializeBusiness, state.businessValues]
  );

  return <BusinessContext.Provider value={memoizedValue}>{children}</BusinessContext.Provider>;
}