import { useContext } from "react";

import { PersonalContext } from "../context";

export const usePersonalContext = () => {
    const context = useContext(PersonalContext);

    if (!context) throw new Error('usePersonalContext context must be use inside PersonalProvdier');

    return context;
}