import { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Typography,
} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import TextInputField from "../TextInputField";
import SelectInputField from "../SelectInputField";
import CheckboxField from "../CheckboxField";
import { useSupportContext } from "../../context/supportOption/hook";
import { useBookkeeperContext } from "../../context/bookkeeper/hook";
import axios from "axios";
import { useDataContext } from "../../context/data/hook";

interface Props {
  open: boolean;
  handleClose: () => void;
  clientId: string;
}

const EditClientModal = ({ open, handleClose, clientId }: Props) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const { initialize } = useDataContext();

  const { supportValues } = useSupportContext();
  const { bookkeeperValues } = useBookkeeperContext();

  const [owners, setOwners] = useState<string[]>([""]);

  const addOwner = () => {
    setOwners((prevOwners) => [...prevOwners, ""]);
  };

  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const [supportOption, setSupportOption] = useState<string>("");
  const [bookkeeper, setBookkeeper] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [monthlyFee, setMonthlyFee] = useState<string>("");
  const [bookkeeping, setBookkeeping] = useState<boolean>(false);
  const [personalReturn, setPersonalReturn] = useState<boolean>(false);
  const [qboPaid, setQboPaid] = useState<boolean>(false);

  useEffect(() => {
    if (clientId)
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL +
            "/api/get_client_status/" +
            clientId
        )
        .then((response) => {
          console.log("response = ", response);

          const status = response.status;
          const success = (status >= 200 && status < 400) || status === 304;

          if (success) {
            setSupportOption(response.data.support);
            setBookkeeper(response.data.bookkeeper);
            setBusinessName(response.data.client_name);
            setMonthlyFee(response.data.fee);
            setBookkeeping(response.data.bookkeeping);
            setPersonalReturn(response.data.personal_return);
            setQboPaid(response.data.qbo_paid);

            const existOwners = response.data.owners
            setOwners(existOwners);
          }
        })
        .catch((err) => {
          console.log("There is an error:", err);
        });
  }, [clientId]);

  const onSubmit = () => {
    if (businessName === "" || monthlyFee === "" || supportOption === "")
      return;
    setLoadingButton(true);

    const updateOwners = owners.filter(owner => owner !== '');

    const update_status = {
      client_name: businessName,
      owners: updateOwners,
      fee: monthlyFee,
      support: supportOption,
      bookkeeping: bookkeeping,
      bookkeeper: bookkeeper,
      personal_return: personalReturn,
      qbo_paid: qboPaid,
    };

    console.log('update_status:', update_status);
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/api/update_client_stauts/" + clientId,
        { update_status: update_status }
      )
      .then((response) => {
        initialize();
        handleClose();
        setLoadingButton(false);
      })
      .catch((error) => {
        setLoadingButton(false);
        console.error("There was an error!", error);
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="new-client-modal-title"
      aria-describedby="new-client-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMediumScreen ? 450 : 1000,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "10px",
        }}
      >
        <h2 id="new-client-modal-title">Edit Client</h2>
        <Box
          justifyContent={"space-between"}
          sx={{ display: isMediumScreen ? "" : "flex" }}
        >
          <Box>
            <Box>
              <TextInputField
                label="Business Name"
                value={businessName}
                setValue={setBusinessName}
              />
            </Box>
            {owners.map((owner, index) => (
              <Box key={index} marginTop={"20px"}>
                <TextInputField
                  label="Owner Name"
                  value={owner}
                  setValue={(newOwnerName) => {
                    const newOwners = [...owners];
                    newOwners[index] = newOwnerName;
                    setOwners(newOwners);
                  }}
                />
              </Box>
            ))}
            <Box display={"flex"} justifyContent={"center"} marginTop={"20px"}>
              <Button startIcon={<AddCircleRoundedIcon />} onClick={addOwner}>
                Add Owner
              </Button>
            </Box>
            <Box marginTop={"20px"}>
              <TextInputField
                label="Monthly Fee($)"
                value={monthlyFee}
                setValue={setMonthlyFee}
                numberType={true}
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: isMediumScreen ? "450px" : "300px",
              marginTop: isMediumScreen ? "20px" : "",
            }}
          >
            <Box>
              <SelectInputField
                label="Support Option"
                datas={supportValues}
                value={supportOption}
                setValue={setSupportOption}
              />
            </Box>
            <Box marginTop={"20px"}>
              <SelectInputField
                label="Bookkeeper"
                datas={bookkeeperValues}
                value={bookkeeper}
                setValue={setBookkeeper}
              />
            </Box>
          </Box>
        </Box>

        <Box marginTop={"20px"}>
          <CheckboxField
            label="Bookkeeping Client"
            value={bookkeeping}
            setValue={setBookkeeping}
          />
          <CheckboxField
            label="Personal return"
            value={personalReturn}
            setValue={setPersonalReturn}
          />
          <CheckboxField
            label="QBO Paid"
            value={qboPaid}
            setValue={setQboPaid}
          />
        </Box>

        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            sx={{
              paddingX: "40px",
              paddingY: "5px",
              border: "1px solid #491D70",
              color: "#FFF",
              backgroundColor: "#491D70",
              "&:hover": {
                backgroundColor: "#491d80",
              },
              textTransform: "none",
              fontSize: "16px",
              width: "160px",
            }}
            onClick={onSubmit}
          >
            {loadingButton ? (
              <CircularProgress size={"20px"} color="inherit" />
            ) : (
              <Typography fontSize={"13px"}>Edit Client</Typography>
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditClientModal;
