import { useState } from "react";
import axios from "axios";
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import TextInputField from "../TextInputField";

interface Props {
  open: boolean;
  handleClose: () => void;
  email: string;
}

const ResetPasswordModal = ({ open, handleClose, email }: Props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = () => {
    if (password === "" || confirmPassword === "") return;
    setLoadingButton(true);
    const param = {
      email: email,
      password: password,
      password_confirmation: password,
    };

    axios
      .patch(process.env.REACT_APP_BACKEND_URL + "/v1/users/password", {
        user: param,
      })
      .then((response) => {
        const status = response.status;
        const success = (status >= 200 && status < 400) || status === 304;

        if (success) {
          setLoadingButton(false);
        }
      })
      .catch((error) => {
        console.log("error = ", error);
        setLoadingButton(false);
      });
    setPassword("");
    setConfirmPassword("");
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="new-client-modal-title"
        aria-describedby="new-client-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Typography>Reset Password</Typography>
          <Box marginTop={"40px"}>
            <Box>
              <TextInputField
                label="Password"
                value={password}
                setValue={setPassword}
                type="password"
              />
            </Box>
            <Box marginTop={"30px"}>
              <TextInputField
                label="Confirm Password"
                value={confirmPassword}
                setValue={setConfirmPassword}
                type="password"
              />
            </Box>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              marginTop={"60px"}
            >
              <Button
                sx={{
                  paddingX: "40px",
                  paddingY: "5px",
                  border: "1px solid #491D70",
                  color: "#FFF",
                  backgroundColor: "#491D70",
                  "&:hover": {
                    backgroundColor: "#491d80",
                  },
                  textTransform: "none",
                  fontSize: "16px",
                  width: "160px",
                }}
                onClick={handleSubmit}
                disabled={loadingButton}
              >
                {loadingButton ? (
                  <CircularProgress size={"20px"} color="inherit" />
                ) : (
                  <Typography fontSize={"13px"}>Create</Typography>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ResetPasswordModal;
