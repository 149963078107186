import { useGridApiContext } from "@mui/x-data-grid";
import { Box, IconButton } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

const DataGridSelectComponent = (params: any) => {
  const apiRef = useGridApiContext();

  if (params.value === '') {
    return null
  }

  const handleClick = (event: any) => {
    event.stopPropagation(); // to not select row
    apiRef.current.startCellEditMode({
      id: params.id,
      field: params.field
    });
    apiRef.current.setCellFocus(params.id, params.field);
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginX: '10px',
        cursor: "pointer"
      }}
      onClick={handleClick}
    >
      <span className="font-12px">{params.value ? params.value : 'Not Started'}</span>
      <IconButton size="small" sx={{ padding: 0 }}>
        <ArrowDropDown />
      </IconButton>
    </Box>
  );
};

export default DataGridSelectComponent;