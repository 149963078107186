import { useState } from "react";
import { Box, Typography, Button } from "@mui/material"
import { DeleteRounded } from '@mui/icons-material';
import axios from "axios";
import NewSettingModal from "../NewSettingModal";
import ResetPasswordModal from "../ResetPasswordModal";

interface Props {
  title: string,
  newButtonName: string,
  values?: (null | Record<string, any>)[]
  api: string
  labelName: string
  emailField?: boolean
  updateValues?: () => void;
}

const SettingCard = ({ title, newButtonName, values, api, labelName, emailField, updateValues }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const handleResetPasswordModalOpen = () => setOpenResetPasswordModal(true);
  const handleResetPasswordModalClose = () => setOpenResetPasswordModal(false);

  const handleDelete = (id: string) => {
    axios.delete(process.env.REACT_APP_BACKEND_URL + '/api/delete_' + api + `/${id}`)
      .then((response) => {
        if(response.status === 200 && updateValues)
          updateValues();
      }).catch((error: any) => {
        console.log('There is an error: ', error);
      })
  }

  return (
    <>
      <Box borderRadius={'8px'} padding={'10px'} border={'1px solid #F0F0F0'} overflow={"auto"} >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography>{title}</Typography>
          <Button sx={{
            paddingX: '10px', paddingY: '5px', border: '1px solid #491D70', color: '#FFF', backgroundColor: '#491D70', '&:hover': {
              backgroundColor: '#491d80',
            }, textTransform: 'none'
          }} size="small" onClick={handleOpen}>{newButtonName}</Button>
        </Box>
        <Box marginTop={'20px'} paddingX={'10px'} alignItems={'center'} sx={{ minWidth: title === 'Firm Users' ? '500px' : '' }} >
          {
            values?.map((value, index) => {
              return (
                <Box key={index} display={'flex'} justifyContent={'space-between'} marginTop={'10px'} height={'30px'}>
                  <Typography minWidth={'150px'} fontSize={'13px'} display={'flex'} justifyContent={"flex-start"} alignItems={"center"}>{value?.name + (value?.status ? `(${value?.status})` : '')}</Typography>
                  {value?.email && <Typography minWidth={'180px'} fontSize={'13px'} display={'flex'} justifyContent={"flex-start"} alignItems={"center"}>{value?.email}</Typography>}
                  {value?.email && <Button sx={{
                    paddingX: '10px', paddingY: '5px', border: '1px solid #491D70', color: '#FFF', backgroundColor: '#491D70', minWidth: '150px', '&:hover': {
                      backgroundColor: '#491d80',
                    }, textTransform: 'none'
                  }} size="small" onClick={() => {setResetPasswordEmail(value?.email); handleResetPasswordModalOpen();}} >Reset Password</Button>}
                  {value?.deletable ? <Box width={'68px'}><Button onClick={() => { handleDelete(value.id) }}><DeleteRounded color="error" /></Button></Box> : <Box width={'68px'}></Box>}
                </Box>
              )
            })
          }
        </Box>
      </Box>
      <NewSettingModal open={openModal} handleClose={handleClose} title={newButtonName} api={api ? api : ''} labelName={labelName} emailField={emailField} updateValues={updateValues} />
      <ResetPasswordModal open={openResetPasswordModal} handleClose={handleResetPasswordModalClose} email={resetPasswordEmail} />
    </>
  )
}

export default SettingCard