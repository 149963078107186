import { useContext } from "react";

import { BusinessContext } from "../context";

export const useBusinessContext = () => {
    const context = useContext(BusinessContext);

    if (!context) throw new Error('useBusinessContext context must be use inside BusinessProvdier');

    return context;
}