import { useState } from "react";
import axios from "axios";
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import TextInputField from "../TextInputField";

interface Props {
  open: boolean; //modal hopen
  handleClose: () => void; //modal colse
  title: string; //modal name (top-left)
  api: string; //create setting api
  labelName: string; //text input label-name
  emailField?: boolean; //check that has emailField
  updateValues?: () => void; //update Values
}

const NewSettingModal = ({
  open,
  handleClose,
  title,
  api,
  labelName,
  emailField,
  updateValues,
}: Props) => {
  const [value, setValue] = useState("");
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    if (value === "" || (emailField && email === "")) return;
    setLoadingButton(true);
    const param = {
      name: value,
      email: email,
      password: "password",
      password_confirmation: "password",
    };
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL +
          (api === "firm_user" ? "/v1/users/sign_up" : "/api/create_" + api),
        param
      )
      .then((response) => {
        const status = response.status;
        const success = (status >= 200 && status < 400) || status === 304;

        if (success && updateValues) {
          updateValues();
        }
        setLoadingButton(false);
      })
      .catch((error) => {
        console.log("error = ", error);
        setLoadingButton(false);
      });
    setValue("");
    setEmail("");
    setLoadingButton(false);
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="new-client-modal-title"
        aria-describedby="new-client-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Typography>{title}</Typography>
          <Box marginTop={"40px"}>
            <Box>
              <TextInputField
                label={labelName}
                value={value}
                setValue={setValue}
              />
            </Box>
            {emailField && (
              <Box marginTop={"30px"}>
                <TextInputField
                  label="User Email"
                  value={email}
                  setValue={setEmail}
                />
              </Box>
            )}
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"} marginTop={"60px"}>
            <Button
              sx={{
                paddingX: "40px",
                paddingY: "5px",
                border: "1px solid #491D70",
                color: "#FFF",
                backgroundColor: "#491D70",
                "&:hover": {
                  backgroundColor: "#491d80",
                },
                textTransform: "none",
                fontSize: "16px",
                width: "160px",
              }}
              onClick={handleSubmit}
              disabled={loadingButton}
            >
              {loadingButton ? (
                <CircularProgress size={"20px"} color="inherit" />
              ) : (
                <Typography fontSize={"13px"}>Create</Typography>
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default NewSettingModal;
