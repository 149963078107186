import { useContext } from "react";

import { SupportContext } from "../context";

export const useSupportContext = () => {
    const context = useContext(SupportContext);

    if (!context) throw new Error('useSupportContext context must be use inside SupportProvdier');

    return context;
}