import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

const AllRoutes = () => {

  return (
    <>
      <PrivateRoute />
      <PublicRoute />
    </>
  );
};

export default AllRoutes;
