import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/auth/hook";
import Login from "../pages/Login";

const PublicRoute = () => {
  const { auth } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if(auth) 
        navigate('/');
  }, [auth])

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </>
  );
};

export default PublicRoute;
