import { useMemo, useReducer, useEffect, useCallback } from "react";
import axios from "axios";
import { ActionMapType, DataStateType, DataType } from "./type";
import { DataContext } from "./data-context";

enum Types {
  INITIAL = "INITIAL",
  UPDATE = "UPDATE",
}

type Payload = {
  [Types.INITIAL]: {
    allData: DataType[];
    data: DataType[];
    taxYear: number;
    checkComplete:  boolean
  };
  [Types.UPDATE]: {
    allData: DataType[];
    data: DataType[];
    taxYear: number;
    checkComplete:  boolean
  };
};

const initialState: DataStateType = {
  allData: [],
  data: [],
  taxYear: new Date().getFullYear() - 1,
  checkComplete: false
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const reducer = (state: DataStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        allData: action.payload.allData,
        data: action.payload.data,
        taxYear: action.payload.taxYear,
        checkComplete: action.payload.checkComplete
      };
    case Types.UPDATE:
      return {
        allData: action.payload.allData,
        data: action.payload.data,
        taxYear: action.payload.taxYear,
        checkComplete: action.payload.checkComplete
      };
    default:
      return state;
  }
};

type Props = {
  children: React.ReactNode;
};

export function DataProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    if (process.env.REACT_APP_BACKEND_URL) {
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/api/get_all_client_statuses")
        .then((response) => {
          const filteredData = response.data.filter((item: any) => {
            if(state.checkComplete)
              return (
                item.tax_year === state.taxYear &&
                (
                  (item.personal_return_status === "Complete" ||
                    !item.personal_return) &&
                  item.business_return_status === "Complete"
                )
              );
            else return (
              item.tax_year === state.taxYear &&
              !(
                (item.personal_return_status === "Complete" ||
                  !item.personal_return) &&
                item.business_return_status === "Complete"
              )
            ); 
          });
          dispatch({
            type: Types.INITIAL,
            payload: {
              allData: response.data,
              data: filteredData,
              taxYear: state.taxYear,
              checkComplete: state.checkComplete
            },
          });
        })
        .catch((error) => {
          console.log("There is an error");
          dispatch({
            type: Types.INITIAL,
            payload: {
              allData: [],
              data: [],
              taxYear: state.taxYear,
              checkComplete: false
            },
          });
        });
    }
  }, [state.taxYear]);

  useEffect(() => {
    initialize();
  }, [state.taxYear, initialize]);

  const updateToComplete = useCallback(async () => {
    if (process.env.REACT_APP_BACKEND_URL) {
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/api/get_all_client_statuses")
        .then((response) => {
          const filteredData = response.data.filter((item: any) => {
            return (
              item.tax_year === state.taxYear &&
              (item.personal_return_status === "Complete" ||
                !item.personal_return) &&
              item.business_return_status === "Complete"
            );
          });
          console.log('filteredData = ', filteredData);
          dispatch({
            type: Types.UPDATE,
            payload: {
              allData: response.data,
              data: filteredData,
              taxYear: state.taxYear,
              checkComplete: true
            },
          });
        })
        .catch((error) => {
          console.log("There is an error");
          dispatch({
            type: Types.UPDATE,
            payload: {
              allData: [],
              data: [],
              taxYear: state.taxYear,
              checkComplete: false
            },
          });
        });
    }
  }, [state.taxYear]);

  const setTaxYear = useCallback(
    async (year: number) => {
      const filteredData = state.allData.filter((item: any) => {
        return item.tax_year === year;
      });
      dispatch({
        type: Types.UPDATE,
        payload: {
          allData: state.allData,
          data: filteredData,
          taxYear: year,
          checkComplete: state.checkComplete
        },
      });
    },
    [state.allData]
  );

  const setCheckComplete = useCallback(async (value: boolean) => {
    console.log('value = ', value);
    dispatch({
      type: Types.UPDATE,
      payload: {
        allData: state.allData,
        data: state.data,
        taxYear: state.taxYear,
        checkComplete: value
      },
    });
  }, [state.allData, state.data, state.taxYear])

  const memoizedValue = useMemo(
    () => ({
      data: state.data,
      taxYear: state.taxYear,
      checkComplete: state.checkComplete,
      initialize,
      setCheckComplete,
      setTaxYear,
      updateToComplete,
    }),
    [initialize, setCheckComplete, setTaxYear, updateToComplete, state.data, state.taxYear]
  );

  return (
    <DataContext.Provider value={memoizedValue}>
      {children}
    </DataContext.Provider>
  );
}
