import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import SettingCard from "../../components/SettingCard";
import { useBusinessContext } from "../../context/businessReturnStatus/hook";
import { usePersonalContext } from "../../context/personalReturnStatus/hook";
import { useSupportContext } from "../../context/supportOption/hook";
import { useBookkeeperContext } from "../../context/bookkeeper/hook";
import { useFirmUserContext } from "../../context/firmUsers/hook";
import { BusinessAPI, FirmUserAPI, BookkeepersAPI, PersonalAPI, SupportAPI } from "../../utils/api";

const Settings = () => {
  const { businessValues, initializeBusiness } = useBusinessContext();
  const { personalValues, initializePersonal } = usePersonalContext();
  const { supportValues, initializeSupport } = useSupportContext();
  const { bookkeeperValues, initializeBookkeeper } = useBookkeeperContext();
  const { firmUserValues, initializeFirmUser } = useFirmUserContext();

  return (
    <>
      <Box marginX={'40px'} marginY={'20px'}>
        <Box display={'flex'} justifyContent={"space-between"}>
          <Typography fontSize={'30px'}>Settings</Typography>
          <Link to="/"><Button sx={{
            paddingX: '40px', paddingY: '5px', border: '1px solid #491D70', color: '#FFF', backgroundColor: '#491D70', '&:hover': {
              backgroundColor: '#491d80',
            }, textTransform: 'none'
          }} >Return to Dashboard</Button></Link>
        </Box>
        <Box marginTop={'40px'}>
          <Grid container spacing={2}>
            <Grid item lg={4} md={6} xs={12}>
              <SettingCard title="Business Return Status" newButtonName="New Status" values={businessValues} api={BusinessAPI} labelName="Business Status" updateValues={initializeBusiness} />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <SettingCard title="Personal Return Status" newButtonName="New Status" values={personalValues} api={PersonalAPI} labelName="Personal Status" updateValues={initializePersonal} />
            </Grid>
            <Grid item lg={4} xs={12}>
              <SettingCard title="Support Options" newButtonName="New Option" values={supportValues} api={SupportAPI} labelName="Support Option" updateValues={initializeSupport} />
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={'30px'}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <SettingCard title="Bookkeepers" newButtonName="New BK" values={bookkeeperValues} api={BookkeepersAPI} labelName="Book Keeper" updateValues={initializeBookkeeper} />
            </Grid>
            <Grid item md={8} xs={12}>
              <SettingCard title="Firm Users" newButtonName="New User" values={firmUserValues} api={FirmUserAPI} labelName="Firm User" emailField={true} updateValues={initializeFirmUser} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default Settings;