import { useContext } from "react";

import { DataContext } from "../context";

export const useDataContext = () => {
    const context = useContext(DataContext);

    if (!context) throw new Error('useDataContext context must be use inside DataProvider');

    return context;
}