import { useMemo, useReducer, useEffect, useCallback } from "react";
import axios from "axios";
import { ActionMapType, AuthStateType, AuthType } from "./type";
import { AuthContext } from "./auth-context";

enum Types {
  INITIAL = "INITIAL",
  UPDATE = "UPDATE",
}

type Payload = {
  [Types.INITIAL]: {
    auth: AuthType;
  };
  [Types.UPDATE]: {
    auth: AuthType;
  };
};

const initialState: AuthStateType = {
  auth: null,
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const reducer = (state: AuthStateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        auth: action.payload.auth,
      };
    case Types.UPDATE:
      return {
        auth: action.payload.auth,
      };
    default:
      return state;
  }
};

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    if (process.env.REACT_APP_BACKEND_URL) {
      const authToken = localStorage.getItem('token');
      axios
        .get(process.env.REACT_APP_BACKEND_URL + "/v1/users/logged_in", {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.data.logged_in)
            dispatch({
              type: Types.INITIAL,
              payload: {
                auth: response.data.user,
              },
            });
          else
            dispatch({
              type: Types.INITIAL,
              payload: {
                auth: null,
              },
            });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          dispatch({
            type: Types.INITIAL,
            payload: {
              auth: null,
            },
          });
        });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const updateAuth = useCallback((authValue: AuthType) => {
    dispatch({
      type: Types.UPDATE,
      payload: {
        auth: authValue,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      auth: state.auth,
      updateAuth,
    }),
    [updateAuth, state.auth]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
