import { Modal, Box, Typography, Button } from "@mui/material";
import { useDataContext } from "../../context/data/hook";
import axios from "axios";

interface Props {
  open: boolean;
  handleClose: () => void;
  clientId: string;
}

const DeleteStatusModal = ({ open, handleClose, clientId }: Props) => {
  const { initialize } = useDataContext();

  const handleDelete = (id: string) => {
    axios
      .delete(
        process.env.REACT_APP_BACKEND_URL + "/api/delete_client_status/" + id
      )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          handleClose();
          initialize();
        }
      })
      .catch((err) => {
        console.log("There is error:", err);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="new-client-modal-title"
        aria-describedby="new-client-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 450,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Typography align="center" fontSize={"30px"}>
            Are you sure?
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"center"}
            mt={"20px"}
            gap={"20px"}
          >
            <Button color="warning" onClick={() => {handleDelete(clientId)}}>Delete</Button>
            <Button color="inherit" onClick={handleClose}>Cancel</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteStatusModal;
