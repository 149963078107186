import { Box, TextField, InputLabel } from "@mui/material"

interface Props {
    label: string,
    value: string | number,
    setValue: (value: string) => void,
    numberType?: boolean,
    type?: string
}

const TextInputField = ({ label, value, setValue, numberType, type }: Props) => {
    return (
        <>
            <Box display={"flex"} border={'1px solid #E5E5E5'} borderRadius={'5px'}>
                <Box display={'flex'} justifyContent={"center"} alignItems={"center"} bgcolor={'#F2F5F7'} paddingLeft={'5px'}>
                    <InputLabel sx={{ width: '150px', fontSize: '13px', fontWeight: 700 }}>{label}</InputLabel>
                </Box>
                <TextField size="small" sx={{ width: '400px' }} variant="standard" value={value} onChange={(event) => { setValue(event.target.value) }} type={numberType ? 'number' : (type === "password" ? type : 'text')} />
            </Box>
        </>
    )
}

export default TextInputField