import { useCallback, useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Button, Typography } from "@mui/material";
import {
  TaskAltOutlined,
  CancelOutlined,
  DeleteRounded,
  EditRounded
} from "@mui/icons-material";
import axios from "axios";
import DataGridToolbar from "../../components/DataGridToolbar";
import { useDataContext } from "../../context/data/hook";
import { useBusinessContext } from "../../context/businessReturnStatus/hook";
import { usePersonalContext } from "../../context/personalReturnStatus/hook";
import { handleCellEditStart } from "../../utils/handleCellEditStart";
import DataGridSelectComponent from "../../components/DataGridSelectComponent";
import EditClientModal from "../../components/EditClientModal";
import DeleteStatusModal from "../../components/DeleteStatusModal";

const Dashboard = () => {
  const { data, checkComplete, initialize} = useDataContext();
  const { businessValues } = useBusinessContext();
  const { personalValues } = usePersonalContext();

  const [businessData, setBusinessData] = useState<string[]>([]);
  const [personalData, setPersonalData] = useState<string[]>([]);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editClientId,  setEditClientId] = useState('');

  const handleEditModalOpen = () => setEditModalOpen(true);
	const handleEditModalClose = () => setEditModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteClientId, setDeleteClientId] = useState('');

  const handleDeleteModalOpen = () => setDeleteModalOpen(true);
  const handleDeleteModalClose = () => setDeleteModalOpen(false);

  useEffect(() => {
    const newBusinessData: string[] = businessValues.map(
      (businessValue) => businessValue?.name
    );
    setBusinessData(newBusinessData);

    const newPersonalData: string[] = personalValues.map(
      (personalValue) => personalValue?.name
    );
    setPersonalData(newPersonalData);
  }, [businessValues, personalValues]);

  // useEffect(() => {
  //   initialize();
  // }, []);

  const columns: GridColDef[] = [
    {
      field: "client_name",
      headerName: "Client name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: "owners",
      headerName: "Owners",
      flex: 2,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "fee",
      headerName: "Fee",
      type: "number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        return <Typography marginTop={"13px"}>${params.value}</Typography>;
      },
    },
    {
      field: "support",
      headerName: "Support",
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "bookkeeping",
      headerName: "Bookkeeping",
      type: "boolean",
      flex: 1,
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.value ? (
          <TaskAltOutlined color="success" />
        ) : (
          <CancelOutlined color="error" />
        );
      },
    },
    {
      field: "bookkeeper",
      headerName: "Bookkeeper",
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "personal_return",
      headerName: "Personal Return",
      type: "boolean",
      flex: 1,
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.value ? (
          <TaskAltOutlined color="success" />
        ) : (
          <CancelOutlined color="error" />
        );
      },
    },
    {
      field: "qbo_paid",
      headerName: "QBO Paid",
      type: "boolean",
      flex: 1,
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.value ? (
          <Box
            component="img"
            alt="QBO_paid"
            src="QBO_paid.svg"
            sx={{ width: 30, height: 30 }}
          ></Box>
        ) : (
          <></>
        );
      },
    },
    {
      field: "business_return_status",
      headerName: "Business Return Status",
      type: "singleSelect",
      flex: 1,
      headerAlign: "center",
      disableColumnMenu: true,
      editable: true,
      valueOptions: businessData,
      renderCell: DataGridSelectComponent,
    },
    {
      field: "personal_return_status",
      headerName: "Personal Return Status",
      type: "singleSelect",
      flex: 1,
      headerAlign: "center",
      disableColumnMenu: true,
      editable: true,
      valueOptions: personalData,
      renderCell: DataGridSelectComponent,
    },
    {
      field: "delete_action",
      headerName: "",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              // handleDelete(params.id.toString());
              setDeleteClientId(params.id.toString());
              handleDeleteModalOpen();
            }}
          >
            <DeleteRounded color="error" />
          </Button>
        ); // This will log the params when the button is clicked
      },
    },
    {
      field: "edit_action",
      headerName: "",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              handleEditModalOpen();
              setEditClientId(params.id.toString());
            }}
          >
            <EditRounded color="success" />
          </Button>
        ); // This will log the params when the button is clicked
      },
    },
  ];

  const handleProcessRowUpdate = useCallback((newRow: any) => {
    const updatedRow = { ...newRow, isNew: false };
    const update_status = {
      business_return_status: updatedRow.business_return_status,
      personal_return_status: updatedRow.personal_return_status,
    };

    axios
      .post(
        process.env.REACT_APP_BACKEND_URL +
          "/api/update_client_stauts/" +
          updatedRow.id,
        { update_status: update_status }
      )
      .then((response) => {
        initialize();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    return updatedRow;
  }, [checkComplete]);

  return (
    <Box
      sx={{ width: "100%", height: "900px", marginTop: "30px", border: "none", overflow: "auto" }}
    >
      <DataGrid
        style={{ minWidth: "900px"}}
        rows={Array.isArray(data) ? data : []}
        columns={columns}
        slots={{ toolbar: DataGridToolbar }}
        onCellEditStart={handleCellEditStart}
        slotProps={{
          toolbar: {
            csvOptions: { allColumns: true },
            showQuickFilter: true,
            sx: {
              display: "flex",
              justifyContent: "flex-start",
            },
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : ""
        }
        processRowUpdate={handleProcessRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 40,
            },
          },
        }}
      />
      <EditClientModal open={editModalOpen} handleClose={handleEditModalClose} clientId={editClientId} />
      <DeleteStatusModal open={deleteModalOpen} handleClose={handleDeleteModalClose} clientId={deleteClientId} />
    </Box>
  );
};

export default Dashboard;
