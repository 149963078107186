import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import React from 'react'

interface Props {
	label: string,
	datas: (null | Record<string, any>)[],
	value: string,
	setValue: (value: string) => void,
}

const SelectInputField = ({ label, datas, value, setValue }: Props) => {
	return (
		<FormControl fullWidth size='small'>
			<InputLabel id="demo-simple-select-label">{label}</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={value}
				label={label}
				onChange={(event) => {setValue(event.target.value);}}
			>
				{
					datas.map((data, index) => {
						return  (
							<MenuItem key={index} value={data?.name}>{data?.name}</MenuItem>
						)
					})
				}
			</Select>
		</FormControl>
	)
}

export default SelectInputField