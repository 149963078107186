import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/auth/hook";
import Dashboard from "../pages/Dashboard";
import Settings from "../pages/Settings";
import { useEffect } from "react";

const PrivateRoute = () => {
  const { auth } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if(!auth) 
        navigate('/login');
  }, [auth])

  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />}></Route>
        <Route path="/settings" element={<Settings />}></Route>
      </Routes>
    </>
  );
};

export default PrivateRoute;
