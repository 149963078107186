import { FormControlLabel, Checkbox } from "@mui/material";

interface Props {
    label: string,
    value: boolean,
    setValue: (value: boolean) => void
}

const CheckboxField = ({label, value, setValue}: Props) => {
    return (
        <FormControlLabel control={<Checkbox checked={value} onChange={(event) => {setValue(event.target.checked)}} />} label={label} labelPlacement="end" />
    )
}

export default CheckboxField;