import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth/hook";
import { Typography, Box, TextField, Button } from "@mui/material";

const Login = () => {

  const navigate = useNavigate();

  const { auth, updateAuth } = useAuthContext();

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if(auth)
      navigate('/');
  }, [auth])

  const handleLogin = () => {

    const param = {
      email: email,
      password: password
    }

    axios.post(process.env.REACT_APP_BACKEND_URL + '/v1/users/login', {user: param})
      .then((response) => {
        if(response.data.logged_in)
        {
          localStorage.setItem('token', response.data.authToken)
          updateAuth(response.data.user)
          navigate("/");
        }
      })
      .catch(error => {
        console.log('There is error: ', error);
      })
  }

  return (
    <>
      <Box display={"flex"} justifyContent={"center"} py={"40px"}>
        <Box width={'400px'} component={"form"}>
          <Typography fontSize={"50px"} textAlign={'center'}>Log In</Typography>
          <Box mt={'30px'}>
            <TextField label="Email" fullWidth onChange={(event) => {setEmail(event.target.value)}} />
          </Box>
          <Box mt={"30px"}>
            <TextField label="Password" type="password" fullWidth onChange={(event) => {setPassword(event.target.value)}} />
          </Box>
          <Box mt={"30px"} display={"flex"} justifyContent={"center"}>
            <Button sx={{backgroundColor: "skyblue"}} fullWidth onClick={handleLogin} >Login</Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
